<template>
  <div class="password">
    <Nav></Nav>
    <div class="pass-con desktop-present">
      <div class="pass-title">
        您的位置：<span style="color: red">首页</span>>>个人信息>>修改密码
      </div>
      <div class="pass-detail">
        <div class="tip">请输入新密码，并牢记</div>
        <div class="reset">
          <div>
            <span>原密码：</span>
            <el-input v-model="oldPassWord" show-password></el-input>
          </div>
          <div>
            <span>新密码：</span>
            <el-input
              v-model="newPassWord"
              show-password
              @blur="onRegClick"
            ></el-input>
          </div>
          <div>
            <span>重复新密码：</span>
            <el-input v-model="repeat" show-password></el-input>
          </div>
        </div>
        <div class="btn"><el-button @click="onReset">重置</el-button></div>
      </div>
    </div>
    <div class="pass-con mobile-present">
      <div class="pass-title">
        您的位置：<span style="color: red">首页</span>>>个人信息>>修改密码
      </div>
      <div class="pass-detail">
        <div class="tip">请输入新密码，并牢记</div>
        <div class="reset">
          <div>
            <span>原密码：</span>
            <el-input v-model="oldPassWord" show-password></el-input>
          </div>
          <div>
            <span>新密码：</span>
            <el-input
              v-model="newPassWord"
              show-password
              @blur="onRegClick"
            ></el-input>
          </div>
          <div>
            <span>重复新密码：</span>
            <el-input v-model="repeat" show-password></el-input>
          </div>
        </div>
        <div class="btn"><el-button @click="onReset">重置</el-button></div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RDUtils from "@/api/RDUtils";
import RD from "@/api/RD";
import SHA1 from "js-sha1";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      myInfo: {}, // 个人信息
      oldPassWord: "", // 原密码
      newPassWord: "",
      repeat: "",
    };
  },
  created() {},
  methods: {
    //   重置密码
    onReset() {
      var thiz = this;
      if (thiz.oldPassWord == "") {
        thiz.$message("原密码不能为空");
        return;
      }
      if (thiz.newPassWord == "") {
        thiz.$message("新密码不能为空");
        return;
      }

      if (thiz.newPassWord != thiz.repeat) {
        thiz.$message("新密码两次输入不一致");
        return;
      }

      if (
        this.newPassWord != "" &&
        !RDUtils.checkWeekPassword(this.newPassWord)
      ) {
        alert("新密码至少8位，包含数字，大写和小写之中的至少两种");
        return;
      }

      RD.user()
        .resetPassword(SHA1(thiz.oldPassWord), SHA1(thiz.newPassWord))
        .then(() => {
          thiz.$message("修改密码成功");
          thiz.$router.push("/myInfo.html");
        });
    },
    // 验证强密码
    onRegClick() {
      var thiz = this;
      console.log("mx");
      if (
        thiz.newPassWord != "" &&
        !RDUtils.checkWeekPassword(thiz.newPassWord)
      ) {
        alert("新密码至少8位，包含数字，大写和小写之中的至少两种");
        return;
      }
    },
  },
};
</script>

<style scoped lang="less">
.password {
  height: auto;
  background-color: #f1f1f1;
  .pass-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    min-height: calc(100vh - 54rem);
    .pass-title {
      height: 4rem;
      background-color: #ccc;
      font-size: 1.3rem;
      line-height: 4.5rem;
      text-align: left;
      padding-left: 1rem;
    }
    .pass-detail {
      height: 56.2rem;
      background-color: #dfdfdf;
      text-align: left;
      .tip {
        padding-top: 1rem;
        font-size: 1.8rem;
        font-weight: bold;
        margin-left: 1rem;
      }
      .reset {
        margin-left: 3rem;
        margin-top: 3rem;
        div {
          height: 4rem;
          font-size: 1.6rem;
          margin: 1rem 0rem;
          span {
            display: inline-block;
            width: 11.5rem;
          }
          .el-input {
            width: 30%;
          }
        }
      }
      .btn {
        margin-left: 30rem;
        margin-top: 2rem;
      }
    }
  }
  .pass-con.mobile-present {
    width: 750px;
    margin: 0 auto;
    min-height: 588px;
    .pass-title {
      height: 40px;
      background-color: #ccc;
      font-size: 16px;
      line-height: 40px;
      text-align: left;
      padding-left: 1rem;
    }
    .pass-detail {
      height: 562px;
      background-color: #dfdfdf;
      text-align: left;
      .tip {
        padding-top: 10px;
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px;
      }
      .reset {
        margin-left: 30px;
        margin-top: 30px;
        div {
          height: 40px;
          font-size: 16px;
          margin: 1rem 0rem;
          span {
            display: inline-block;
            width: 120px;
          }
          .el-input {
            width: 30%;
          }
        }
      }
      .btn {
        margin-left: 30rem;
        margin-top: 2rem;
      }
    }
  }
}
</style>
